import type { TCreateUserPayload } from '@tl-lms/types/user-manage'

function queryUser(email: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/auth-user/user-exist/', 'post', undefined, undefined, {
    email
  })
}

function createStudent(payload: TCreateUserPayload) {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<{
    id_slug: string
  }>('/api/users/', 'POST', payload)
}

type TUseUserManageReturnType = {
  queryUser: typeof queryUser
  createStudent: typeof createStudent
}

export function useUserManage(): TUseUserManageReturnType {
  return {
    queryUser,
    createStudent
  }
}
